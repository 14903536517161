@use 'global-styles/marble/base/base.scss';

.wrapper {
	--color-link: var(--color-white);
	--color-active-tab: var(--color-white);
	--color-text: var(--color-white);
	--color-text: rgba(255, 255, 255, 0.75);
	--color-focus-state-background: var(--color-grey-900);

	background-color: var(--color-met-red-medium);
	left: 0;
	padding: var(--spacing-s) 0;
	position: fixed;
	top: 0;
	transform: translateY(-100%);
	transition: transform 0.3s ease-in;
	width: 100%;
	z-index: base.z('search-modal');

	@media (prefers-reduced-motion) {
		transform: none;
		transition: none;
	}
}

.isVisible {
	transform: translateY(0);
}

.tabDrawer {
	margin: var(--spacing-micro) var(--spacing-component-margin);
}

.search {
	--color-link: var(--color-grey-900);
	--color-text: var(--color-grey-700);
	color: var(--color-text);
}

.search input:focus {
	color: var(--color-text-header);
}

.close {
	color: var(--color-text);
	position: absolute;
	right: var(--spacing-component-margin);
	top: 16px;
	transition: opacity 0.4s;

	@media (prefers-reduced-motion) {
		transition: none;
	}

	&:hover,
	&:focus-visible {
		opacity: 0.8;
	}
	

	@media screen and (min-width: base.$breakpoint-s) {
		top: var(--spacing-xxs);
	}
}
